<template>
	<div id="news" v-if="iconload">

		<!-- 在线咨询 -->
		<div class=" w-100 d-flex justify-content-center">
			<img class="banner-img w-100 h-100" :src="$imgURL + newsPageBanner" alt="" />
		</div>

		<!-- 九大课程列表 -->
		<div class="news-wrap   px-1 px-sm-0">
			<ul class="nav-wrap flex container ">
				<!-- <li class="f-0-c-c" @click="toggleNav(i)" :class="[navIndex == i ? 'active' : '']" v-for="(item, i) in cates" :key="i">
					<img class="li-img" :src="item.icon" alt="" />
					<span class="font">{{ item.name }}</span>
					<div class="pos-abs"></div>
					<img class="pos-abs2" src="@/assets/img/sanjiao.png" alt="" />
				</li> -->
				<li class="f-0-c-c" @click="toggleNav(0)" :class="[navIndex == 0 ? 'active' : '']">
					<img class="li-img" v-if="navIndex == 0" :src="$imgURL+icon.about_img4" alt="" />
					<img class="li-img" v-else :src="$imgURL+icon.about_img4_2" alt="" />
					<span class="font">近期活动</span>
					<div class="pos-abs"></div>
					<img class="pos-abs2" src="@/assets/img/sanjiao.png" alt="" />
				</li>
				<li class="f-0-c-c" @click="toggleNav(1)" :class="[navIndex == 1 ? 'active' : '']">
					<img class="li-img" v-if="navIndex == 1" :src="$imgURL+icon.about_img5" alt="" />
					<img class="li-img" v-else :src="$imgURL+icon.about_img5_2" alt="" />
					<span class="font">新闻资讯</span>
					<div class="pos-abs"></div>
					<img class="pos-abs2" src="@/assets/img/sanjiao.png" alt="" />
				</li>

			</ul>

			<div class="content container " v-for="(item, index) in newsList" :key="index">

				<div class="d-flex w-100 item flex-row justify-content-between  bg-white rounded-sm p-x-1 p-0-5 ">
					<div class="  p-0  d-flex justify-content-center ">
						<img style="object-fit: contain;width: 326px!important;" class="w-100 h-100 d-none d-sm-block"
							:src="$imgURL + item.image" alt="" />
						<img style="object-fit: contain;width: 1.3rem!important;" class="w-100 h-100 d-block d-sm-none"
							:src="$imgURL + item.image_h5" alt="" />
					</div>
					<div class="right-wrap  w-100  p-0  pl-1 d-flex justify-content-between font flex-column">
						<div class="d-flex flex-column">
							<div class="title text-over font-md">{{ item.title }}</div>
							<div class="text hidden3 pt-0-5  font">
								{{ item.desc }}
							</div>
						</div>
						<div class="btns-wrap clearfix" @click="jumpNewsDetail(item.id)">
							<div class="btns f-c-c font">内容详情</div>
						</div>
					</div>

				</div>


			</div>
			<div class="text-center">
				<el-pagination style="height: 80px;" @prev-click="prev" @current-change="current" @next-click="next"
					background layout=" prev, pager, next" :page-size="pageSize" :total="total">
				</el-pagination>
			</div>


		</div>



		<!-- <footers id="footer" /> -->
	</div>
</template>


<script>
export default {
	data() {
		return {
			iconload: false,
			requireList: [

				'about_img4',
				'about_img4_2',
				'about_img5',
				'about_img5_2',
			],
			icon: {

			},

			page: 1,
			total: 0,
			pageSize: 0,
			navIndex: 0,
			newsPageBanner: "",
			cates: [{
				type: 1,
				name: "近期活动",
				icon: require("../assets/img/jqhd.png"),
				active: true,
			},
			{
				type: 2,
				name: "新闻资讯",
				icon: require("../assets/img/xwzx.png"),
				active: false,
			},
			],
			newsList: [],
		};
	},
	mounted() {
		let type = this.$route.query.type || 0;
		this.navIndex = Number(type);
		this.getNewsList();
		this.getNewsPageBanner();
		this.getConfig()
	},
	methods: {
		getConfig() {

			this.requireList.forEach((v) => {
				this.onConfig(v);
			});
		},
		onConfig(key) {
			var that = this;
			that.api.config(key).then((res) => {
				if (res.data.code == 1) {
					that.icon[key] = res.data.data
					if (key == 'about_img5_2') {
						that.iconload = true
					}
				}
			});
		},
		prev(e) {
			console.log(e);
			this.page = e
			this.getNewsList()
		},
		next(e) {
			console.log(e);
			this.page = e
			this.getNewsList()
		},
		current(e) {
			console.log(e);
			this.page = e
			this.getNewsList()
		},
		scroll() {
			document.getElementById('footer').scrollIntoView()
		},
		toggleNav(index) {
			if (this.navIndex == index) return;
			this.page = 1
			this.navIndex = index;
			this.getNewsList()

		},
		jumpNewsDetail(id) {
			console.log(id);
			//跳转到新闻详情
			this.$router.push({
				path: '/news_detail',
				query: {
					id: id
				}
			})
		},
		getNewsList() {
			var params = new URLSearchParams();
			params.append("type", this.navIndex + 1);
			params.append("page", this.page);
			this.$axios({
				method: "post",
				url: this.$apiURL + "api/news/getNewsList",
				data: params
			}).then((res) => {
				if (res.code < 1) {
					console.log("网络错误");
					return false;
				}
				if (res.data.data.configs.data.length > 0) {
					this.newsList = res.data.data.configs.data;

					this.total = res.data.data.configs.total
					this.pageSize = res.data.data.configs.per_page
				}
			});
		},

		getNewsPageBanner() {
			var params = new URLSearchParams();
			params.append("key", 'banner_news');
			this.$axios({
				method: "get",
				url: this.$apiURL + "api/config/config?" + params
			}).then((res) => {
				if (res.code < 1) {
					console.log("网络错误");
					return false;
				}

				if (res.data.data) {
					this.newsPageBanner = res.data.data
				}
			});
		},

	},
};
</script>
<style>
.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
	background-color: #fff !important;
	color: #9FD9F6 !important;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li:hover {
	color: #9FD9F6 !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
	background-color: #9FD9F6 !important;
	color: #fff !important;
}

.el-pagination.is-background .el-pager li:not(.disabled).active:hover {
	color: #fff !important;
}
</style>
<style lang="scss" scoped>
@media (max-width: 576px) {
	.btns {
		width: 1rem !important;
		height: 0.28rem !important;
	}

	.hidden3 {
		-webkit-line-clamp: 1 !important;
	}

	#news {
		.news-wrap {
			.content {
				transform: translateY(-0.2rem) !important;

				.item {
					height: 1.6rem !important;
				}
			}

			.nav-wrap {
				transform: translateY(-0.2rem) !important;
				height: 1.1rem !important;

				li {
					.li-img {
						width: 0.4rem !important;
						height: 0.4rem !important;
						margin: 0.16rem 0 !important;
					}

					.pos-abs2 {
						bottom: 0.015rem !important;
						width: 0.3rem !important;
						height: 0.2rem !important;
					}

					.pos-abs {
						height: 0.8rem !important;
					}
				}


			}
		}
	}

	.linian {
		height: 2rem !important;
	}

	.linian-lh {
		height: 1rem !important;
	}

	.linian-rh {
		height: 2.2rem !important;
		overflow: hidden;
	}

	.linian-rh1 {
		height: 2.1rem !important;
	}
}

#news {

	.pos-fixed-img {
		position: fixed;
		right: 0;
		top: 30%;
		width: 1.2rem;
	}

	.banner-img {
		width: 100%;
		// min-height: 2rem;
		object-fit: cover;
	}

	.news-wrap {
		background-image: url("../assets/img/bg.png");

		background-size: 100%;
		// padding: 0.63rem 3.6rem;
		box-sizing: border-box;


		.nav-wrap {
			transform: translateY(-1.125rem);
			// width: 12rem;
			height: 2.25rem;
			background: #ffffff;
			box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.12);
			border-radius: 0.15rem;
			margin: auto;
			font-size: 0.18rem;

			li {
				width: 50%;
				height: 100%;
				position: relative;

				.pos-abs {
					position: absolute;
					width: 2px;
					height: 1.16rem;
					background: #f2f2f2;
					top: 0;
					bottom: 0;
					right: 0;
					margin: auto;
				}

				.pos-abs2 {
					display: none;
					position: absolute;
					bottom: 0.03rem;
					transform: translate(0, 84%);
					left: 0;
					right: 0;
					width: 0.6rem;
					height: 0.4rem;
					margin: auto;
				}

				&.active .pos-abs2 {
					display: block;
				}

				&:last-child(1) {
					display: none;
				}

				.li-img {
					margin: 0.3rem 0 0.26rem 0;
					width: 0.88rem;
					height: 0.88rem;

					span {
						font-size: 0.18rem;
					}
				}
			}

			.f-0-c-c {
				cursor: pointer;
			}
		}

		.home-title {
			margin-bottom: 0.73rem;
		}

		.content {
			transform: translateY(-1.125rem);
			// width: 12rem;
			margin: 0.4rem auto 0;

			.item {
				width: 100%;
				height: 2.4rem;
				background: #ffffff;
				// margin-bottom: 0.14rem;
				padding: 0.25rem 0.3rem 0.26rem 0.44rem;
				box-sizing: border-box;
				cursor: default;
				position: relative;

				.right-wrap {
					.title {
						font-size: 0.24rem;

						color: #9fd9f6;
						margin-bottom: 0.08rem;
					}

					.text {
						// padding-top: 0.21rem;
						box-sizing: border-box;
						border-top: 1px solid #9fd9f6;
						font-size: 0.16rem;
					}

					.btns-wrap {
						// position: absolute;
						// bottom: 0.3rem;
						right: 0.37rem;
						display: flex;
						flex-direction: row-reverse;

						.btns {
							width: 1.12rem;
							height: 0.32rem;
							background: #fdd23e;
							border-radius: 0.16rem;
							color: #333;
							font-size: 0.16rem;
							cursor: pointer;
						}
					}
				}

				.img {
					width: 3.26rem;
					height: 2.14rem;
					//background: #d8d8d8;

					border: 1px solid #979797;
					margin-right: 0.36rem;
				}
			}
		}
	}
}
</style>
